import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Functions from '../../common/Functions';
import SessionManager from 'wise-api/dist/index';
import { EVENTOS_SOCKET } from '../../common/Constants';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        bottom: 0,
        top: 0,
        height: 'calc(100vh - 40px)',
        width: (props) => (props.width),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        padding: 0,
        overflowY: 'hidden',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 50px)',
            width: '100%',
        },
        ...theme.palette.paper
    },
    meet: {
        height: '100%',
        width: '100%'
    },
    notificacao: {
        padding: theme.spacing(5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    divEncerrado: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
        alignItems: 'center'
    }
}));

const buttons = [
    'microphone', 'camera', 'fodeviceselection', 'etherpad', 'settings', 'videoquality', 'filmstrip', 'shortcuts', 'tileview', 'help'
];

const v4h = new SessionManager();

const VideoForHealth = ({ user, chatOpen, filesOpen, socket, setFilesOpen, setChatOpen }) => {
    let width = '50%';
    if (!chatOpen && !filesOpen) {
        width = '100%';
    } else if (!chatOpen || !filesOpen) {
        width = '75%';
    }
    useSignals();
    const classes = useStyles({ width });
    const meetRef = useRef(null);
    const [videoAtivo, setVideoAtivo] = useState(false);

    useEffect(() => {
        v4h.setup({
            login: global.gConfig.api_v4h_login,
            senha: global.gConfig.api_v4h_senha,
            domain: global.gConfig.api_v4h_domain,
            baseUrl: global.gConfig.api_v4h_base_url,
        });

        function aoFecharJanela() {
            v4h.session.stopConference().then(() => {
                console.log("Conferência finalizada");
            });
        }
        window.addEventListener('beforeunload', aoFecharJanela);

        // return () => {
        //     window.removeEventListener('beforeunload', aoFecharJanela);
        // }
    }, []);

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO_INICIAL, ({ active }) => {
                if (active) {
                    tratarAtendimentoPorVideo(active);
                }
            });

            socket.value.on(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO, ({ active }) => {
                tratarAtendimentoPorVideo(active);
            });

            // Pedindo o status atual do atendimento por vídeo.
            socket.value.emit(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO_INICIAL);
        }

        return () => {
            v4h.session.stopConference().then(() => {
                console.log("Conferência finalizada");
            });
        }
    });

    const tratarAtendimentoPorVideo = (active) => {
        setVideoAtivo(active);
        setChatOpen(!active);
        setFilesOpen(!active);
        if (active) {
            if (Functions.isAttender(user.roleType)) {
                if (global.gConfig.api_v4h_recording_button) {
                    buttons.push('recording');
                }
                buttons.push('mute-everyone');
            }
            const options = {
                parentNode: document.getElementById('meet'),
                userInfo: {
                    displayName: user.name
                },
                buttons: buttons
            }

            v4h.room.join(`Appointment${user.appointmentId}Encounter${user.encounterId}`).then((response) => {
                v4h.session.startConference(response.sessionId, options).then(() => {
                    setTimeout(() => {
                        meetRef.current.firstChild.style.height = '100%';
                        meetRef.current.firstChild.style.width = '100%';
                    }, 1000);
                });
            });
        } else {
            v4h.session.stopConference().then(() => {
                console.log("Conferência finalizada");
            });
        }
    }

    if (!videoAtivo) return <></>

    return (
        <div className={classes.videoContainer}>
            <div id="meet" className={classes.meet} ref={meetRef}></div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(VideoForHealth);
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttNotification,
    SttAlertTitle,
    SttTranslateHook
} from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
    notificacao: {
        padding: theme.spacing(5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    divEncerrado: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: 'calc(100vh - 40px)',
        alignItems: 'center'
    }
}));

const AtendimentoEncerrado = ({ chatOpen, filesOpen }) => {
    let width = '50%';
    if (!chatOpen && !filesOpen) {
        width = '100%';
    } else if (!chatOpen || !filesOpen) {
        width = '75%';
    }
    const classes = useStyles({ width });
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <div className={classes.divEncerrado}>
            <SttNotification severity='info' className={classes.notificacao}>
                {
                    global.gConfig.mensagem_fim_atendimento_html
                        ?
                        <div dangerouslySetInnerHTML={{ __html: global.gConfig.mensagem_fim_atendimento_html }}></div>
                        :
                        <>
                            <SttAlertTitle>{strings.atendimentoEncerrado}</SttAlertTitle>
                            {strings.mensagemEncerrado}
                            <br />
                            {strings.mensagemHistorico}
                        </>
                }
            </SttNotification>
        </div>
    );
};

export default AtendimentoEncerrado;
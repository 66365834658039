import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        // type: "light",
        primary: {
            main: '#0A6FB8'
        },
        secondary: {
            main: '#4A96D2'
        },
        tertiary: {
            main: '#E8E8E8'
        },
        error: {
            main: '#F5222D'
        },
        success: {
            main: '#52C41A'
        },
        text: {
            primary: '#4F4F4F',
            secondary: '#828282'
        },
        background: {
            default: "#FBFBFB",
            border: "#D9D9D9"
        },
        texto_col1_tabela: {
            main: '#000000 !important'
        },
        indicacao_fala_usuario: {
            primary: '#005CAA',
        },
        balao_chat: {
            primary: '#ECFEDB',
        },
        balao_chat_outro: {
            primary: '#FFFFFF'
        },
        texto_chat: {
            primary: '#000000',
            secondary: '#6E6E6E'
        },
        tab_chat: {
            fontSize: '15px',
            fontWeight: 600,
            color: '#6E6E6E',
            '@media (min-width: 0px)': {
                minWidth: '130px'
            },
            "&$selected": {
                color: '#005CAA',
            }
        },
        input_base: {
            color: '#000000AA',
        },
        paper: {
            backgroundColor: '#E8E8E8'
        },
        app_bar: {
            backgroundColor: '#FFFFFF'
        },
        color_typo_12346: {
            color: '#2C2C2C'
        },
        color_typo_5: {
            color: '#005CAA',
        },
        svg_icon: {
            color: '#2C2C2C',
        },
        floating_button: {
            backgroundColor: '#FFFFFF',
        },
        icon_button: {
            color: '#2C2C2C',
            '&:hover': {
                backgroundColor: '#E8E8E8',
                color: '#005CAA',
                opacity: 1
            }
        },
        table_head: {
            color: '#6E6E6E',
            backgroundColor: '#FFFFFF !important',
        },
        table_row: {
            backgroundColor: '#FFFFFF',
            '& td': {
                color: '#6E6E6E',
            },
            '&:hover': {
                color: '#005CAA !important',
                backgroundColor: '#E6FFEA33',
                '& td': {
                    color: '#005CAA !important',
                },
            },

        },
        table_cell: {
            backgroundColor: '#FFFFFF',
            color: '#6E6E6E',
        },
        table_sort_label: {
            borderBottom: '1px solid #6E6E6E !important',
            '& span': {
                color: '#6E6E6E !important',
                '&:hover': {
                    backgroundColor: '#EEEEEE11 !important',
                    color: '#005CAA !important'
                },
                '&$active': {
                    backgroundColor: '#EEEEEE !important', botao_chamada_encerrar: {
                        backgroundColor: '#D93025',
                        '& span': {
                            color: '#FFFFFF',
                        },
                        '&:hover': {
                            backgroundColor: '#BF2A20',
                        },
                    },
                    botao_mic_on: {
                        backgroundColor: '#FFFFFF',
                        '& span': {
                            color: '#2C2C2C',
                        },
                        '&:hover': {
                            backgroundColor: '#E6FFEA',
                            '& span': {
                                color: '#2F9E41',
                            },
                        },
                    },
                    botao_mic_off: {
                        backgroundColor: '#00000066',
                        '& span': {
                            color: '#FFFFFF',
                        },
                        '&:hover': {
                            backgroundColor: '#00000080',
                        },
                    },
                    color: '#005CAA !important',

                },
            },
            '& span svg': {
                color: '#6E6E6E !important',
                '&:hover': {
                    backgroundColor: '#EEEEEE11 !important',
                    color: '#005CAA !important'
                },
                '&$active': {
                    backgroundColor: '#EEEEEE !important',
                    color: '#005CAA !important',

                },
            },
        },
        menu: {
            backgroundColor: '#FFFFFF',
            color: '#6E6E6E'
        },
        menuListItem: {
            backgroundColor: '#FFFFFF',
            color: '#6E6E6E',
            '&:hover': {
                backgroundColor: '#EEEEEE',
                color: '#005CAA',
                opacity: 1
            },
        },
        menuListItemSelected: {
            backgroundColor: '#FFFFFF !important',
            color: '#005CAA',
            opacity: 1
        },
        menuIcon: {
            color: '#6E6E6E',
            '&:hover': {
                backgroundColor: '#EEEEEE',
                color: '#005CAA'
            },
            '&$selected': {
                backgroundColor: '#EEEEEE',
                color: '#005CAA',

            }
        }
    },
    typography: {
        fontFamily: ['Roboto Medium', 'Inter', '-apple-system', 'Roboto', '"Segoe UI"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        fontSize: 14,
    },
    props: {
        MuiTypography: {
            variantMapping: {
                body2: 'span',
            },
        },
    },
    overrides: {
        // OK
        MuiTypography: {
            h1: {
                fontSize: '1.7rem',
                lineHeight: '130%',
                fontWeight: 600,
                '@media (min-width: 0px) and (max-width: 840px)': {
                    fontSize: '1.5rem'
                }
            },
            h2: {
                fontSize: '1.5rem',
                lineHeight: '150%',
                fontWeight: 500,
                '@media (min-width: 0px) and (max-width: 840px)': {
                    fontSize: '1.2rem'
                }
            },
            h3: {
                fontSize: '1.2rem',
                lineHeight: '160%',
                fontWeight: 500,
                '@media (min-width: 0px) and (max-width: 840px)': {
                    fontWeight: 600
                }
            },
            h4: {
                fontSize: '1.1rem',
                lineHeight: '160%',
                fontWeight: 500,
                '@media (min-width: 0px) and (max-width: 840px)': {
                    fontSize: '1.1rem'
                }
            },
            h5: {
                fontSize: '0.9rem',
                lineHeight: '100%',
                fontWeight: 600
            },
            h6: {
                fontSize: '0.8rem',
                lineHeight: '150%',
                fontWeight: 600
            },
        },
        //OK
        MuiTabs: {
            indicator: {
                backgroundColor: '#005CAA',
                height: '3px'
            }
        },
        //OK
        MuiTabScrollButton: {
            root: {
                width: '25px',
                color: '#005CAA'
            }
        },
        //OK 
        MuiTableCell: {
            head: {
                fontSize: '14px',
                fontWeight: 600,
                textAlign: 'left',
            },
            body: {
                fontSize: '14px',
                fontWeight: 500
            }
        },
        // OK
        MuiTableRow: {
            root: {
                textAlign: 'left',
            }
        },
        // OK 
        MuiListItemText: {
            root: {
                marginLeft: '12px'
            }
        },
        // OK 
        MuiList: {
            padding: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        // OK 
        MuiListItemIcon: {
            root: {
                minWidth: '24px',
            }
        },
        MuiAppBar: {
            root: {
                backgroundColor: '#FFFFFF !important',
                color: '#2C2C2C !important',
            }
        },
        MuiBox: {
            root: {
                border: '0px'
            }
        }
    },
});

import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { SttContainer } from '@stt-componentes/core';
import Chat from './chat';
import ModalUsuarioOnline from '../componentes/modal-usuario-online';
import Anexos from '../componentes/anexos';
import Video from '../componentes/video';
import AguardeAtendimento from './aguarde-atendimento';
import { EVENTOS_SOCKET, FHIR_ENCOUNTER_STATUS } from '../common/Constants';
import AtendimentoEncerrado from '../componentes/atendimento-encerrado';
import { setUser } from '../reducers/actions';
import Functions from '../common/Functions';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        maxWidth: '100%',
        padding: 0
    },
}));

const Teleatendimento = ({ user, setUser }) => {
    const classes = useStyles();
    useSignals();

    const [usuarioOnline, setUsuarioOnline] = useState(false);
    const [atendimentoIniciado, setAtendimentoIniciado] = useState(false);
    const [chatOpen, setChatOpen] = useState(true);
    const [filesOpen, setFilesOpen] = useState(false);

    const socket = useSignal();

    useEffect(() => {
        if (user) {
            const options = { query: user, forceNew: false };
            if (global.gConfig.url_base_socket_path !== '') {
                options.path = global.gConfig.url_base_socket_path;
            }
            socket.value = io.connect(global.gConfig.url_base_socket, options);
            //Evento recebido quando o usuário já está conectado em outra aba/navegador
            socket.value.on(EVENTOS_SOCKET.USUARIO_ONLINE, () => {
                setUsuarioOnline(true);
                socket.value.disconnect();
            });

            if (!user.encounterFinished) {
                socket.value.on(EVENTOS_SOCKET.STATUS_ATENDIMENTO, (data) => {
                    const { currentStatus } = data;
                    if (currentStatus === FHIR_ENCOUNTER_STATUS.IN_PROGRESS) {
                        setAtendimentoIniciado(true);
                    }
                });

                socket.value.on(EVENTOS_SOCKET.ATENDIMENTO_ENCERRADO, () => {
                    setUser({ ...user, encounterFinished: true });
                    socket.value.disconnect();
                });
            }

        }
    }, [user]);

    return (
        <SttContainer className={classes.root}>
            {
                socket.value ?
                    <>
                        {
                            !usuarioOnline
                                ?
                                (atendimentoIniciado || user.encounterFinished || Functions.isPractitioner(user.role)) ?
                                    <>
                                        <Chat chatOpen={chatOpen} setChatOpen={setChatOpen} socket={socket} />
                                        {
                                            user.encounterFinished ?
                                                <AtendimentoEncerrado chatOpen={chatOpen} filesOpen={filesOpen} />
                                                :
                                                <Video chatOpen={chatOpen} filesOpen={filesOpen} socket={socket} setFilesOpen={setFilesOpen} setChatOpen={setChatOpen} />
                                        }
                                        <Anexos filesOpen={filesOpen} setFilesOpen={setFilesOpen} socket={socket} />
                                    </>
                                    :
                                    < AguardeAtendimento />
                                :
                                <ModalUsuarioOnline online={usuarioOnline} />
                        }
                    </> : null
            }
        </SttContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teleatendimento);
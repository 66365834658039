export const EVENTOS_SOCKET = {
    ALTERAR_STATUS_ATENDIMENTO_VIDEO: 'alterar_status_atendimento_video',
    ATENDIMENTO_ENCERRADO: 'atendimento_encerrado',
    ATUALIZAR_LISTA_ANEXOS: 'atualizar_lista_anexos',
    ENVIAR_MENSAGEM: 'enviar_mensagem',
    FINALIZAR_ATENDIMENTO: 'finalizar_atendimento',
    MENSAGEM_ENVIADA: 'mensagem_enviada',
    STATUS_ATENDIMENTO: 'status_atendimento',
    STATUS_ATENDIMENTO_VIDEO: 'status_atendimento_video',
    STATUS_ATENDIMENTO_VIDEO_INICIAL: 'status_atendimento_video_inicial',
    USUARIO_NOVO_SALA: 'usuario_novo_sala',
    USUARIO_ONLINE: 'usuario_online',
    USUARIO_SAIU_SALA: 'usuario_saiu_sala',
    USUARIOS_ONLINE: 'usuarios_online'
};

export const FHIR_ENCOUNTER_STATUS = {
    IN_PROGRESS: 'in-progress',
}
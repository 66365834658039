import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
    SttModal,
    SttButton,
    SttGrid,
    SttBasicFileSelect,
    SttLoading,
    SttAlerta,
    SttTranslateHook
} from '@stt-componentes/core';
import { getHeadersFormData } from '../../request';
import { EVENTOS_SOCKET } from '../../common/Constants';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: '10px',
        marginBottom: '30px'
    },
    inputFile: {
        display: 'none'
    }
}));

const Anexo = ({  modalOpen, setModalOpen, user, socket }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    //Alertas
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');

    const handleClose = () => {
        setModalOpen(open => !open);
    }

    const changeHandler = (event) => {
        if (!event) {
            setSelectedFile(null);
            return;
        }

        if (event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const anexarArquivo = () => {
        const data = new FormData();
        data.append('file', selectedFile);
        data.append('partition', user.partition);
        data.append('user', JSON.stringify(user));
        data.append('patientName', user.patientName);

        setIsUploading(true);
        axios.post(`${global.gConfig.url_base_backend}/file`, data, {
            headers: getHeadersFormData(),
            onUploadProgress: (event) => {
                let progress = Math.round((event.loaded * 100) / event.total);
                setProgress(progress);
            },
        })
            .then((response) => {
                if (response.data?.sucesso) {
                    setModalOpen(open => !open);
                    setSelectedFile(null);

                    setTituloAlerta(strings.sucesso);
                    setMensagemAlerta(strings.sucessoEnvioAnexo);
                    setTipoAlerta('success');
                    setTimeout(() => {
                        socket.value.emit(EVENTOS_SOCKET.ATUALIZAR_LISTA_ANEXOS);
                    }, 1500);
                    return;
                }
            }).catch((response) => {
                setTituloAlerta(strings.erro);
                setMensagemAlerta(strings.erroEnvioArquivo);
                setTipoAlerta('error');

                console.log(response);
            })
            .finally(() => {
                setMostrarAlerta(true);
                setIsUploading(false);
                setProgress(0);
            });
    }

    return (
        <>
            <SttModal
                title={strings.inserirAnexo}
                open={modalOpen}
                outModalClose={handleClose}
                iconClose={handleClose}
                maxWidth="md"
                fullWidth={true}
                children={
                    <div className={classes.form}>
                        <SttGrid container spacing={3}>
                            <SttGrid item xs={12} md={12}>
                                <SttBasicFileSelect onFileChange={changeHandler} />
                            </SttGrid>
                        </SttGrid>
                    </div>
                }
                footer={
                    <>
                        {
                            socket.value &&
                            <SttButton disabled={!selectedFile || isUploading} variant="contained" color="primary" onClick={anexarArquivo}>
                                {strings.anexar}
                            </SttButton>
                        }
                        <SttButton variant="contained" color="primary" onClick={handleClose}>
                            {strings.fechar}
                        </SttButton>
                    </>
                }
            />
            <SttLoading
                open={isUploading}
                text={strings.enviandoArquivo}
                progress={progress}
            />
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={[
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]}
                onClose={() => setMostrarAlerta(false)}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(withRouter(Anexo));
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AttachIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';
import TabContext from '@material-ui/lab/TabContext';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GetAppIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ArrawBackIcon from '@material-ui/icons/ArrowBack';
import InserirAnexo from './modal-novo-anexo';
import {
    SttHeading,
    SttContainer,
    SttTable,
    SttTableBody,
    SttTableCell,
    SttTableHead,
    SttTableRow,
    SttTableSortLabel,
    SttTranslateHook
} from '@stt-componentes/core';
import Drawer from '@material-ui/core/Drawer';
import { getHeaders } from '../../request';
import { EVENTOS_SOCKET } from '../../common/Constants';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    openAnexosButton: {
        padding: theme.spacing(1.5),
        position: 'absolute',
        top: 40,
        [theme.breakpoints.down('xs')]: {
            top: 50,
        },
        right: 0,
        zIndex: 10,
        borderRadius: '0 0 0 15px',
        ...theme.palette.icon_button,
        ...theme.palette.floating_button,
    },
    drawer: {
        width: (props) => (props.filesOpen ? '25%' : '0'),
        minWidth: (props) => (props.chatOpen ? '350px' : '0'),
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    tableCol1: {
        color: theme.palette.texto_col1_tabela.main,
        maxWidth: '200px'
    },
    tableCell: {
        maxWidth: '150px'
    },
    drawerPaper: {
        width: '25%',
        minWidth: '350px',
        maxWidth: '25%',
        top: 40,
        bottom: 0,
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
            top: 50,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '100%'
        },
        ...theme.palette.paper
    },
    panelAttach: {
        overflowY: 'hidden',
        padding: 0,
        margin: 0,
        height: '100%',
        width: '100%',
    },
    svgIcon: {
        ...theme.palette.svg_icon
    },
    iconButton: {
        ...theme.palette.icon_button
    },
    appBar: {
        ...theme.palette.app_bar
    },
    title: {
        padding: theme.spacing(0, 0, 0, 2),
        ...theme.palette.color_typo_12346
    },
    tableCellHead: {
        ...theme.palette.table_cell,
        ...theme.palette.table_sort_label
    },
    container: {
        overflowX: 'auto',
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(3),
    },
    table: {
        maxWidth: '100%'
    }
}));

const ListaAnexos = ({ patientId, partition, filesOpen, setFilesOpen, socket }) => {
    const classes = useStyles({ filesOpen });
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const [modalOpen, setModalOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [modeAttach, setModeAttach] = useState(true);
    const [currentAttach, setCurrentAttach] = useState(null);
    const [sortingFilter, setSortingFilter] = useState({
        column: '',
        ascDirection: true,
    });
    const URL_BASE_BACKEND = global.gConfig.url_base_backend;

    useEffect(() => {
        requisitarListaAnexos();
    }, [])

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.ATUALIZAR_LISTA_ANEXOS, () => {
                requisitarListaAnexos();
            });
        }
    });

    const menuOptions = [
        {
            icon: OpenInNewIcon,
            title: strings.abrir,
            createOnClick: (row) => () => {
                setModeAttach(mode => !mode);
                setCurrentAttach({ ...row });
            },
        },
        {
            icon: OpenInBrowserIcon,
            title: strings.abrirNovaAba,
            createOnClick: (row) => () => {
                downloadFile(row.url);
            },
        },
    ];

    const requisitarListaAnexos = () => {
        const id = encodeURIComponent(patientId);
        axios.get(`${URL_BASE_BACKEND}/files/${id}/${partition}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data.length > 0) {
                    setFileList(response.data);
                }
            })
            .catch((error) => {
                console.log(error)
                if (fileList.length > 0) return;

                setFileList([]);
            });
    }

    const createSortNomeHandler = () => () => {
        setSortingFilter({
            column: 'Nome',
            ascDirection: !sortingFilter.ascDirection,
        });

        setFileList(
            fileList.sort((a, b) => {
                let compareResult = sortingFilter.ascDirection ? 1 : -1;
                if (a.name > b.name) return compareResult;
                return compareResult * -1;
            })
        );
    };

    const downloadFile = (url) => {
        let link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const openFiles = () => {
        setFilesOpen(open => !open);
    };

    return (
        <>
            <IconButton disableRipple={true} className={classes.openAnexosButton} onClick={openFiles}>
                <AttachIcon />
            </IconButton>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={filesOpen}
                classes={{
                    paper: classes.drawerPaper,
                }} >
                {modeAttach ? (
                    <>
                        <TabContext value="">
                            <AppBar position="sticky" className={classes.appBar}>
                                <Toolbar>
                                    <DescriptionIcon className={classes.svgIcon} />
                                    <SttHeading variant="h2" className={classes.title}>
                                        {strings.anexos} ({fileList.length})
                                    </SttHeading>
                                    <div className={classes.grow} />
                                    <Tooltip title={strings.anexarArquivo}>
                                        <IconButton disableRipple={true} edge="end" onClick={() => setModalOpen(open => !open)} className={classes.iconButton}>
                                            <AttachIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton} onClick={openFiles}>
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                        </TabContext>
                        <SttContainer className={classes.container}>
                            <SttTable stickyHeader aria-label="tabela de arquivos" className={classes.table}>
                                <SttTableHead>
                                    <SttTableRow className={classes.tableRow}>
                                        <SttTableCell align="left" className={classes.tableCellHead}>
                                            <SttTableSortLabel active={sortingFilter.column === 'Nome'}
                                                direction={sortingFilter.ascDirection ? 'asc' : 'desc'}
                                                onClick={createSortNomeHandler()}>
                                                {strings.nome}
                                            </SttTableSortLabel>
                                        </SttTableCell>
                                        <SttTableCell align="center" className={classes.tableCellHead}>
                                            {strings.opcoes}
                                        </SttTableCell>
                                    </SttTableRow>
                                </SttTableHead>
                                <SttTableBody>
                                    {
                                        fileList.length === 0
                                            ?
                                            <SttTableRow className={classes.tableRow}>
                                                <SttTableCell align="center" colSpan={2} className={classes.tableCol1}>
                                                    <Typography>{strings.nenhumAnexo}</Typography>
                                                </SttTableCell>
                                            </SttTableRow>
                                            :
                                            fileList.map((row, index) => (
                                                <SttTableRow key={index} className={classes.tableRow}>
                                                    <SttTableCell align="left" className={classes.tableCol1}>
                                                        <Typography noWrap>{row.name}</Typography>
                                                    </SttTableCell>
                                                    <SttTableCell align="center" className={classes.tableCell}>
                                                        <PopupState variant="popover">
                                                            {(popupState) => (
                                                                <>
                                                                    <IconButton aria-haspopup="true" className={classes.iconButton} {...bindTrigger(popupState)}>
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                    <Menu {...bindMenu(popupState)}>
                                                                        {menuOptions.map((item, index) => (
                                                                            <MenuItem key={index} className={classes.menuListItem} onClick={item.createOnClick(row)}>
                                                                                <ListItemIcon className={classes.menuIcon}>
                                                                                    <item.icon />
                                                                                </ListItemIcon>
                                                                                <ListItemText primary={item.title} />
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Menu>
                                                                </>
                                                            )}
                                                        </PopupState>
                                                    </SttTableCell>
                                                </SttTableRow>
                                            ))
                                    }
                                </SttTableBody>
                            </SttTable>
                        </SttContainer>
                    </>
                ) : (
                    <>
                        <AppBar position="sticky" className={classes.appBar}>
                            <Toolbar>
                                <IconButton aria-label="delete" className={classes.iconButton} onClick={() => setModeAttach(!modeAttach)}>
                                    <ArrawBackIcon />
                                </IconButton>
                                <Typography variant="h2" className={classes.title}>
                                    {currentAttach.name}
                                </Typography>
                                <div className={classes.grow} />
                                <IconButton disableRipple={true} edge="end" className={classes.iconButton} onClick={() => downloadFile(currentAttach.url)}>
                                    <GetAppIcon />
                                </IconButton>
                                <IconButton disableRipple={true} edge="end" className={classes.iconButton} onClick={openFiles}>
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                        <SttContainer className={classes.panelAttach}>
                            <iframe src={currentAttach.url} style={{ width: '100%', height: '100%', overflow: 'auto' }} title='Visualização do arquivo'></iframe>
                        </SttContainer>
                    </>
                )}
            </Drawer>
            <InserirAnexo modalOpen={modalOpen} setModalOpen={setModalOpen} socket={socket} />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        patientId: state.index.user.patientId,
        partition: state.index.user.partition,
    };
};

export default connect(mapStateToProps)(ListaAnexos);
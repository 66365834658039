import React, { useEffect, useState, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import CloseIcon from '@material-ui/icons/Close';
import ChatIcon from '@material-ui/icons/Chat';
import CallEndIcon from '@material-ui/icons/CallEnd';
import TabContext from '@material-ui/lab/TabContext';
import Drawer from '@material-ui/core/Drawer';
import {
    SttHeading,
    SttAlerta,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import ChatTab from '../componentes/chat/chat-tab';
import ChatTabList from '../componentes/chat/chat-tab-list';
import ChatBottom from '../componentes/chat/chat-bottom';
import { EVENTOS_SOCKET } from '../common/Constants';
import { getHeaders } from '../request';
import Functions from '../common/Functions';
import HttpStatus from 'http-status-codes';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    grow: {
        // Talvez possa remover quando incluir o input de busca
        flexGrow: 1,
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        paddingTop: theme.spacing(2)
    },
    drawer: {
        width: (props) => (props.chatOpen ? '25%' : '0'),
        minWidth: (props) => (props.chatOpen ? '400px' : '0'),
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    openChatButton: {
        padding: theme.spacing(1.5),
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            top: 50,
        },
        zIndex: 10,
        borderRadius: '0 0 15px 0',
        ...theme.palette.icon_button,
        ...theme.palette.floating_button,
    },
    drawerPaper: {
        width: '25%',
        minWidth: '400px',
        top: 40,
        bottom: 0,
        height: 'auto',
        [theme.breakpoints.down('xs')]: {
            top: 50,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            width: '100%'
        },
        ...theme.palette.paper
    },
    svgIcon: {
        ...theme.palette.svg_icon
    },
    iconButton: {
        marginLeft: '5px',
        ...theme.palette.icon_button
    },
    appBar: {
        ...theme.palette.app_bar
    },
    title: {
        padding: theme.spacing(0, 0, 0, 2),
        ...theme.palette.color_typo_12346
    },
}));

const chatInicial = [{
    timestamp: new Date().setHours(0, 0, 0, 0),
    mensagens: [],
}];

const Chat = (props) => {
    const {
        encounterFinished,
        appointmentId,
        encounterId,
        chatOpen,
        partition,
        patientId,
        setChatOpen,
        socket,
        userId,
        user,
    } = props;

    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles({ chatOpen });

    useSignals();

    const chatRefs = useRef([]);
    const [chatAtivo, setChatAtivo] = useState(0);
    const [chatHistorico, setChatHistorico] = useState(chatInicial);
    const [baixouHistorico, setBaixouHistorico] = useState(false);
    const [usuariosOnline, setUsuariosOnline] = useState(1);
    const [videoAtivo, setVideoAtivo] = useState(false);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    //Alerta 
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [opcoesAlerta, setOpcoesAlerta] = useState([]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(() => handleCloseAlerta);

    const handleChangeTab = (event, newValue) => {
        setChatAtivo(newValue);
    };

    const sucessoFinalizar = () => {
        setTituloAlerta(strings.sucesso);
        setTipoAlerta('success');
        setOnCloseAlerta(() => () => setMostrarAlerta(false));
        setOpcoesAlerta([
            {
                title: strings.ok,
                onClick: () => setMostrarAlerta(false)
            }
        ]);
        setMensagemAlerta(strings.atendimentoFinalizado);
        setMostrarAlerta(true);
    }

    const finalizarAtendimento = () => {
        setTituloAlerta(strings.atencao);
        setMensagemAlerta(strings.confirmarFinalizacao);
        setTipoAlerta('alert');
        setOpcoesAlerta([
            {
                title: strings.sim,
                onClick: () => {
                    // Encerrando o atendimento
                    socket.value.emit(EVENTOS_SOCKET.FINALIZAR_ATENDIMENTO);
                    setMostrarAlerta(false);

                    if (global.gConfig.video_enabled === 'BBB') {
                        axios.post(`${global.gConfig.url_base_backend}/end-bbb`, { appointmentId, encounterId })
                            .then(function (response) {
                                if (response.status === HttpStatus.OK) {
                                    sucessoFinalizar();
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    } else {
                        sucessoFinalizar();
                    }
                }
            },
            {
                title: strings.nao,
                onClick: () => setMostrarAlerta(false)
            }
        ]);
        setOnCloseAlerta(() => () => setMostrarAlerta(false));
        setMostrarAlerta(true);
    }

    const openChat = () => {
        setChatOpen(open => !open);
    };

    const iniciarChamadaVideo = () => {
        setVideoAtivo(true);
        socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_ATENDIMENTO_VIDEO, { active: true });
    }

    const fecharChamadaVideo = () => {
        setVideoAtivo(false);
        socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_ATENDIMENTO_VIDEO, { active: false });
    }

    useSignalEffect(() => {
        if (socket.value && !encounterFinished) {
            socket.value.on(EVENTOS_SOCKET.MENSAGEM_ENVIADA, (dados) => {
                atualizarMensagemChat(dados);
            });

            socket.value.on(EVENTOS_SOCKET.USUARIOS_ONLINE, (dados) => {
                const { roomSize } = dados;
                setUsuariosOnline(roomSize);
            });

            socket.value.on(EVENTOS_SOCKET.USUARIO_NOVO_SALA, (dados) => {
                const { name, roomSize } = dados;
                setUsuariosOnline(roomSize);

                if (dados.userId === userId) return;

                setChatHistorico(chatsAtuais => {
                    const chats = [...chatsAtuais];
                    const msgAvisoConectado = {
                        ehSeparador: true,
                        atendimento: false,
                        texto: `${name} ${strings.textoConectado}`
                    };
                    chats[0].mensagens.push(msgAvisoConectado);
                    return chats;
                })
            });

            socket.value.on(EVENTOS_SOCKET.USUARIO_SAIU_SALA, (dados) => {
                const { name, roomSize } = dados;
                setUsuariosOnline(roomSize);

                if (dados.userId === userId) return;

                setChatHistorico(chatsAtuais => {
                    const chats = [...chatsAtuais];
                    const msgAvisoConectado = {
                        ehSeparador: true,
                        atendimento: false,
                        texto: `${name} ${strings.textoDesconectado}`
                    };
                    chats[0].mensagens.push(msgAvisoConectado);
                    return chats;
                })
            });

            if (Functions.isAttender(user.roleType)) {
                socket.value.on(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO_INICIAL, ({ active }) => {
                    setVideoAtivo(active);
                });
            }

            // Pedindo a quantidade atual de usuários conectados.
            socket.value.emit(EVENTOS_SOCKET.USUARIOS_ONLINE);
        }
    });

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_backend}/chat/${encodeURIComponent(patientId)}/${partition}`, { headers: getHeaders() })
            .then((response) => {
                const historicoFinal = Functions.chatHistoricoHandler(response, chatHistorico);
                setBaixouHistorico(true);
                setChatHistorico(historicoFinal || chatInicial);
            })
            .catch((err) => {
                setChatHistorico(chatInicial);
            });
    }, []);

    const atualizarMensagemChat = (mensagemEnviada) => {
        setChatHistorico(chatsAtuais => {
            const chats = [...chatsAtuais];
            chats[0].mensagens.push(mensagemEnviada);
            return chats;
        });
        setChatAtivo(0);
    }

    useEffect(() => {
        if (chatRefs.current[chatAtivo]) {
            chatRefs.current[chatAtivo].scrollTop = chatRefs.current[chatAtivo].scrollHeight;
        }
    }, [chatAtivo]);

    useEffect(() => {
        chatRefs.current = chatRefs.current.slice(0, chatHistorico.length);
        chatRefs.current.map((chat) => {
            chat.scrollTop = chat.scrollHeight;
        });
    }, [chatHistorico]);

    return (
        <>
            <IconButton disableRipple={true} className={classes.openChatButton} onClick={openChat}>
                <ChatIcon />
            </IconButton>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={chatOpen}
                classes={{
                    paper: classes.drawerPaper,
                }} >
                <TabContext value={String(chatAtivo)}>
                    <AppBar position="sticky" className={classes.appBar}>
                        <Toolbar>
                            <ChatIcon className={classes.svgIcon} />
                            <SttHeading variant="h2" className={classes.title}>
                                {strings.chat}
                            </SttHeading>
                            <div className={classes.grow} />
                            {
                                !user.encounterFinished && Functions.isAttender(user.roleType) &&
                                <>
                                    {
                                        !videoAtivo ?
                                            <Tooltip title={strings.chamadaVideo}>
                                                <span>
                                                    <IconButton disableRipple={true} edge="end" onClick={iniciarChamadaVideo} className={classes.iconButton} disabled={usuariosOnline < 2}>
                                                        <VideoCallIcon />
                                                    </IconButton>
                                                </span>
                                            </Tooltip> :
                                            <Tooltip title={strings.fecharChamadaVideo}>
                                                <IconButton disableRipple={true} edge="end" onClick={fecharChamadaVideo} className={classes.iconButton}>
                                                    <VideocamOffIcon />
                                                </IconButton>
                                            </Tooltip>
                                    }
                                    <Tooltip title={strings.finalizarAtendimento}>
                                        <IconButton disableRipple={true} edge="end" onClick={finalizarAtendimento} className={classes.iconButton}>
                                            <CallEndIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            <IconButton disableRipple={true} edge="end" className={classes.iconButton} onClick={openChat}>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                        <ChatTabList handleChangeTab={handleChangeTab} chatHistorico={chatHistorico} />
                    </AppBar>
                    {
                        baixouHistorico ?
                            <>
                                {
                                    chatHistorico.map(({ mensagens }, index) => (
                                        <ChatTab mensagens={mensagens} index={index} chatRefs={chatRefs} userId={userId} key={index} />
                                    ))
                                }
                            </> :
                            <div className={classes.carregando}>
                                <SttCircularProgress color="primary" />
                            </div>
                    }

                    <div className={classes.offset} />
                </TabContext>
                <ChatBottom setChatAtivo={setChatAtivo} socket={socket} usuariosOnline={usuariosOnline} />
            </Drawer>
            <SttAlerta
                open={mostrarAlerta}
                title={tituloAlerta}
                message={mensagemAlerta}
                type={tipoAlerta}
                options={opcoesAlerta}
                onClose={onCloseAlerta}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        appointmentId: state.index.user.appointmentId,
        encounterId: state.index.user.encounterId,
        user: state.index.user,
        userId: state.index.user.userId,
        patientId: state.index.user.patientId,
        partition: state.index.user.partition,
        encounterFinished: state.index.user.encounterFinished
    };
};

export default connect(mapStateToProps)(Chat);
import React from 'react';
import BBB from './bbb';
import V4H from './v4h';

const Video = (props) => {
    return (
        global.gConfig.video_enabled === 'BBB'
            ?
            <BBB {...props} />
            :
            <V4H {...props} />
    );
};

export default Video;
import React, { useContext } from 'react';
import Moment from 'react-moment';
import { makeStyles } from '@material-ui/core/styles';
import TabList from '@material-ui/lab/TabList';
import Tab from '@material-ui/core/Tab';
import { SttTranslateHook } from '@stt-componentes/core';

const useStyles = makeStyles((theme) => ({
    tab: {
        ...theme.palette.tab_chat
    }
}));

const ChatTabList = ({ handleChangeTab, chatHistorico }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <TabList
            onChange={handleChangeTab}
            aria-label={strings.historicoChat}
            variant="scrollable"
            TabScrollButtonProps={{
                disableRipple: true,
            }}>
            {chatHistorico.map(({ timestamp }, index) => (
                <Tab className={classes.tab}
                    key={index}
                    value={String(index)}
                    label={
                        <Moment format={strings.formatoData}>
                            {timestamp}
                        </Moment>
                    }
                />
            ))}
        </TabList>
    );
};

export default ChatTabList;
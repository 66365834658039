import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
    SttBox,
    SttExternalHeader,
    SttExternalFooter,
    SttTranslateHook,
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    box: {
        width: '450px',
        height: '200px',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        // border: '1px solid black',
        // backgroundColor: '#DADADA',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    textContent: {
        display: 'flex',
        alignItems: 'normal',
        flexDirection: 'column'
    }
}));

const Aguarde = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <div className={classes.root}>
            <SttExternalHeader instancia={{ logo: global.gConfig.logo_header }} />
            <SttBox className={classes.box}>
                {
                    global.gConfig.default_erro_titulo_html
                        ?
                        <div dangerouslySetInnerHTML={{ __html: global.gConfig.default_erro_titulo_html }}></div>
                        :
                        <SttHeading variant="h1">
                            <ErrorOutlineIcon />
                            {global.gConfig.default_erro_titulo}
                        </SttHeading>
                }
                <SttHeading variant="h4" style={{ marginTop: '20px' }}>
                    {strings.chegouCedo}
                </SttHeading>
            </SttBox>
            <SttExternalFooter instancia={{ logo: global.gConfig.logo_footer }} />
        </div>
    );
};

export default Aguarde;
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SendIcon from '@material-ui/icons/Send';
import {
    SttInput,
    SttContainer,
    SttTranslateHook
} from '@stt-componentes/core';
import { EVENTOS_SOCKET } from '../../common/Constants';
import { useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles((theme) => ({
    bottomBar: {
        top: 'auto',
        bottom: 0
    },
    inputBase: {
        ...theme.palette.input_base
    },
    iconButton: {
        marginLeft: '5px',
        ...theme.palette.icon_button
    },
}));

const ChatBottom = ({ setChatAtivo, socket, usuariosOnline, name, userId, encounterFinished }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const [mensagem, setMensagem] = useState('');

    const handleChangeMensagem = event => {
        setMensagem(event.target.value);
    };

    const tratarTeclaEnter = event => {
        if (!mensagem || mensagem.trim() === '') {
            return;
        }
        if (event.key === 'Enter') {
            enviarMensagem(mensagem);
            setChatAtivo(0);
            setMensagem('');
        }
    };

    const enviarMensagem = (texto) => {
        const dados = {
            author: {
                name: name,
                id: userId
            },
            message: texto,
            timestamp: new Date().getTime()
        };
        socket.value.emit(EVENTOS_SOCKET.ENVIAR_MENSAGEM, dados);
    }

    return (
        <SttContainer>
            <AppBar position="absolute" className={classes.bottomBar} >
                <Toolbar>
                    <SttInput label={strings.digiteMensagem}
                        fullWidth={true}
                        className={classes.inputBase}
                        inputProps={{ 'aria-label': 'mensagem' }}
                        disabled={usuariosOnline <= 1 || encounterFinished}
                        onChange={handleChangeMensagem}
                        onKeyPress={tratarTeclaEnter}
                        value={mensagem}
                    />
                    {
                        (usuariosOnline <= 1 || encounterFinished) ?
                            <IconButton className={classes.iconButton}
                                edge="end"
                                disabled={true}>
                                <SendIcon />
                            </IconButton>
                            :
                            <Tooltip title={strings.enviarMensagem}>
                                <IconButton disableRipple={true}
                                    className={classes.iconButton}
                                    edge="end"
                                    onClick={() => {
                                        if (!mensagem || mensagem.trim() === '') {
                                            return;
                                        }
                                        enviarMensagem(mensagem);
                                        setChatAtivo(0);
                                        setMensagem('');
                                    }}>
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                    }
                </Toolbar>
            </AppBar>
        </SttContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        name: state.index.user.name,
        userId: state.index.user.userId,
        encounterFinished: state.index.user.encounterFinished,
    };
};

export default connect(mapStateToProps)(ChatBottom);
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Erro401 from './erro/401';
import Atrasado from './erro/atrasado';
import Aguarde from './erro/aguarde';
import Erro from './erro';
import Token from './token';

const Home = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route path={['/', "/nao-autorizado"]} exact component={Erro401} />
                <Route path="/erro" exact component={Erro} />
                <Route path="/aguarde" exact component={Aguarde} />
                <Route path="/atrasado" exact component={Atrasado} />
                <Route path="/:token" component={Token} />
            </Switch>
        </BrowserRouter>
    );
};

export default Home;
import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

const initializeApp = (config) => {
    const sentryUrl = 'https://a9e93e976a6c40efb4f38b9d22be7cfc@sentry.telessaude.ufsc.br/2';
    /**
     * SENTRY - https://docs.sentry.io/
     *
     * Inicialização do plugin
     */
    // if (process.env.REACT_APP_SENTRY_RELEASE) {
    //     Sentry.init({
    //         dsn: sentryUrl,
    //         release: process.env.REACT_APP_SENTRY_RELEASE,
    //     });
    // }

    /*eslint-enable */
    global.gConfig = config;
    
    // document.getElementById('titulo_aplicacao').innerText = global.gConfig.instancia_nome;

    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    root.render(<App modulo={'teleatendimentoFhir'} currentLanguage={'ptbr'} />);
};

fetch(`${process.env.PUBLIC_URL}/config/config.json`, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((config) => initializeApp(config));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

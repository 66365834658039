import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import {
    SttBox,
    SttExternalHeader,
    SttExternalFooter,
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    box: {
        width: '450px',
        height: '150px',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
}));

const Aguarde = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SttExternalHeader instancia={{ logo: global.gConfig.logo_header }} />
            <SttBox className={classes.box}>
                {
                    global.gConfig.mensagem_espera_atendimento_html
                        ?
                        <div dangerouslySetInnerHTML={{ __html: global.gConfig.mensagem_espera_atendimento_html }}></div>
                        :
                        <SttHeading variant="h4">
                            {global.gConfig.mensagem_espera_atendimento}
                        </SttHeading>
                }
            </SttBox>
            <SttExternalFooter instancia={{ logo: global.gConfig.logo_footer }} />
        </div>
    );
};

export default Aguarde;
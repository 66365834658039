import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
    SttAlerta,
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { getHeaders } from '../../request';
import { EVENTOS_SOCKET } from '../../common/Constants';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        bottom: 0,
        top: 0,
        height: 'calc(100vh - 40px)',
        width: (props) => (props.width),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        padding: 0,
        overflowY: 'hidden',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100vh - 50px)',
            width: '100%',
        },
        ...theme.palette.paper
    },
    meet: {
        height: '100%',
        width: '100%'
    },
    notificacao: {
        padding: theme.spacing(5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    divEncerrado: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: 'calc(100vh - 40px)',
        alignItems: 'center'
    }
}));

const VideoScreenBBB = ({ user, chatOpen, filesOpen, socket, setFilesOpen, setChatOpen }) => {
    let width = '50%';
    if (!chatOpen && !filesOpen) {
        width = '100%';
    } else if (!chatOpen || !filesOpen) {
        width = '75%';
    }
    const classes = useStyles({ width });
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const [videoAtivo, setVideoAtivo] = useState(false);
    const [joinUrl, setJoinUrl] = useState(null);
    const [erroConectarBBB, setErroConectarBBB] = useState(false);

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO_INICIAL, ({ active }) => {
                if (active) {
                    tratarAtendimentoPorVideo(active);
                }
            });

            socket.value.on(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO, ({ active }) => {
                tratarAtendimentoPorVideo(active);
            });

            setTimeout(() => {
                socket.value.emit(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO_INICIAL);
            }, 1000);
            // Pedindo o status atual do atendimento por vídeo.
        }
    });

    const joinSession = () => {
        const { appointmentId, encounterId, role, name, userId } = user;
        const dadosPost = {
            appointmentId,
            encounterId,
            role,
            name,
            userId
        }
        axios.post(`${global.gConfig.url_base_backend}/connect-bbb`, dadosPost, { headers: getHeaders() })
            .then(function (response) {
                const { data } = response;
                // É necessário aguardar 5 segundos para conectar o usuário na sala.
                // Seguindo a recomendação de: https://www.bigbluemeeting.com/docs/iframe
                setTimeout(() => {
                    setJoinUrl(data.joinUrl);
                }, 5000);
            })
            .catch(function (error) {
                console.log(error)
                setErroConectarBBB(true);
            });
    }

    const tratarAtendimentoPorVideo = (active) => {
        setVideoAtivo(active);
        setChatOpen(!active);
        setFilesOpen(!active);
        if (active) {
            joinSession();
        }
    }

    if (!videoAtivo) return <></>

    return (
        <div className={classes.videoContainer}>
            {
                joinUrl ?
                    <iframe
                        src={joinUrl}
                        width="100%"
                        height="100%"
                        allow="camera *;microphone *;display-capture *;"
                        allowFullScreen
                        title={strings.tituloJanela}></iframe>
                    :
                    <SttLoading
                        open={true}
                        text={strings.aguardeSalaVideo}
                    />
            }
            <SttAlerta
                open={erroConectarBBB}
                title={strings.atencao}
                message={strings.erroConectarBBB}
                type={'alert'}
                onClose={() => {
                    setErroConectarBBB(false);
                }}
                options={[
                    {
                        title: strings.sim,
                        onClick: () => {
                            setErroConectarBBB(false);
                            joinSession();
                        }
                    },
                    {
                        title: strings.nao,
                        onClick: () => {
                            setErroConectarBBB(false);
                        }
                    }
                ]}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(VideoScreenBBB);
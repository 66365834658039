import React, { useContext } from 'react';
import {
    SttModal,
    SttText,
    SttTranslateHook
} from '@stt-componentes/core';

const ModalUsuarioOnline = ({ online }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    return (
        <SttModal
            title={strings.atencao}
            open={online}
            children={
                <SttText variant="body1">
                    {strings.jaOnline}
                </SttText>
            }
        />
    );
};

export default ModalUsuarioOnline;
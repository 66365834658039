import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { setUser } from '../reducers/actions';
import Atendimento from './atendimento';
import {
    SttLoading,
    SttHeader,
    SttTranslateHook
} from '@stt-componentes/core';

const TeleAtendimento = ({ setUser }) => {
    const { token } = useParams();
    const history = useHistory();
    const [validationDone, setValidationDone] = useState(false);
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useEffect(() => {
        // Verifica validade do token
        axios.get(`${global.gConfig.url_base_backend}/${token}`)
            .then(function (response) {
                const { data } = response;

                const tokenApi = data.tokenApi;
                localStorage.setItem('token', tokenApi);
                delete data.tokenApi;

                setUser(data);
                setValidationDone(true);
            })
            .catch(function (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case HttpStatus.UNAUTHORIZED:
                            history.replace('/nao-autorizado');
                            break;
                        case HttpStatus.BAD_REQUEST:
                            history.replace('/atrasado');
                            break;
                        case HttpStatus.LOCKED:
                            history.replace('/aguarde');
                            break;
                        default:
                            history.replace('/erro');
                            break;
                    }
                } else {
                    history.replace('/erro');
                }
            });
    }, []);

    return validationDone ? (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
            />
            <Atendimento />
        </div>
    ) :
        <SttLoading
            open={true}
            text={strings.aguarde}
        />
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (user) => dispatch(setUser(user))
    };
};

export default connect(null, mapDispatchToProps)(TeleAtendimento);